import Cookie from 'js-cookie'
import parseCookie from 'cookie'

export default function setCookies(
  name,
  value,
  res = null,
  expires = new Date(Date.now() + 3600 * 1000 * 24),
) {
  if (res) {
    res.cookie(name, value, {
      path: '/',
      expires,
    })
  } else {
    Cookie.set(name, value, { expires })
  }
}

export function deleteCookies(name, res = null) {
  if (res) {
    res.clearCookie(name)
  } else {
    Cookie.remove(name)
  }
}

export function getCookies(name, req = null) {
  if (req) {
    const parsedCookies = parseCookie.parse(req.headers.cookie || '')
    return parsedCookies[name] ? parsedCookies[name] : null
  }

  return Cookie.get(name)
}
