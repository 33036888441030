import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import Button from '../../../components/Button'
import NextLink from '../../../components/Next/NextLink'
import FieldText from '../../../components/Fields/FieldText'
import auth from '../authSlice'
import setCookies from '../../../services/helpers/cookies'
import validationErrors from '../../../services/helpers/errors'
import IconInfo from '../../../assets/icons/info.svg?sprite'
import usePromise from '../../../hooks/usePromise'

function Login() {
  const formMethods = useForm()
  const dispatch = useDispatch()
  const router = useRouter()

  const [login, onSubmit] = usePromise(async (data) => {
    const token = await dispatch(auth.login(data))

    setCookies('polymer_session_token', token)
    router.replace('/')
  })

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...formMethods}>
      <StyledLoginForm
        autoComplete="off"
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        {login.isRejected && (
          <StyledLoginError>
            <StyledLoginErrorIcon>
              <IconInfo />
            </StyledLoginErrorIcon>
            <StyledLoginErrorText>
              {validationErrors()[login.exception.error] || 'Ошибка сервера'}
            </StyledLoginErrorText>
          </StyledLoginError>
        )}
        <StyledLoginField>
          <FieldText
            autofocus
            placeholder="Логин"
            name="login"
            validateRules={{ required: validationErrors().login_required }}
          />
        </StyledLoginField>

        <StyledLoginField>
          <FieldText
            hasEye
            type="password"
            placeholder="Пароль"
            name="password"
            validateRules={{ required: validationErrors().password_required }}
          />
        </StyledLoginField>

        <StyledLoginControls>
          <StyledControlsCell>
            <Button loading={login.isPending}>Войти</Button>
          </StyledControlsCell>
          <StyledControlsCell>
            <NextLink href="/auth/recovery">
              <StyledForgotLink>Забыли пароль?</StyledForgotLink>
            </NextLink>
          </StyledControlsCell>
        </StyledLoginControls>
      </StyledLoginForm>
    </FormProvider>
  )
}

const StyledLoginForm = styled.form`
  display: block;
  width: 400px;
  max-width: 100%;
  margin-top: 25px;

  ${up('md')} {
    margin-top: 35px;
  }
`

const StyledLoginField = styled.div`
  display: block;
  margin-bottom: 20px;

  ${up('md')} {
    margin-bottom: 25px;
  }
`

const StyledLoginControls = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-width: 0;
  margin-left: -5px;
  margin-right: -5px;
  justify-content: space-between;
  margin-top: 30px;

  ${up('md')} {
    margin-top: 35px;
  }
`
const StyledControlsCell = styled.div`
  flex: 0 0 auto;
  padding-left: 5px;
  padding-right: 5px;
`
const StyledForgotLink = styled.a`
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  color: ${(props) => props.theme.grayDark};
  font-size: 16px;
  line-height: 19px;
  transition: color ${(props) => props.theme.transitionTime};
  text-align: center;
  min-width: 0;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.blueLight};
  }
`

const StyledLoginError = styled.div`
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.colorDanger};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 25px;

  ${up('md')} {
    margin-bottom: 40px;
  }
`
const StyledLoginErrorText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  flex: 1 1 auto;
  align-self: center;

  ${up('md')} {
    font-size: 18px;
    line-height: 21px;
  }
`
const StyledLoginErrorIcon = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 auto;
  margin-right: 13px;
`

Login.defaultProps = {}

Login.propTypes = {}

export default Login
