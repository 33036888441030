import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

const NextLink = ({ href, activeClassName, children, scroll, as, shallow }) => {
  const router = useRouter()

  const child = React.Children.only(children)

  let className = child.props.className || ''
  if (router.pathname === href && activeClassName) {
    className = `${className} ${activeClassName}`.trim()
  }

  return (
    <Link href={href} passHref scroll={scroll} as={as} shallow={shallow}>
      {React.cloneElement(child, { className })}
    </Link>
  )
}

NextLink.defaultProps = {
  href: '/',
  activeClassName: 'next-link-active',
  scroll: true,
  as: undefined,
  shallow: false,
}

NextLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  scroll: PropTypes.bool,
  activeClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  shallow: PropTypes.bool,
}

export default NextLink
