import AuthFormLogin from '../../features/Auth/components/Login'

function Login() {
  return <AuthFormLogin />
}

Login.getInitialProps = async () => {
  return {
    metaTitle: 'Вход',
    layout: 'auth',
  }
}

Login.defaultProps = {}

Login.propTypes = {}

export default Login
